'use client'

import type {
  NavDropdownItemLinkCardStoryblok,
  NavDropdownItemListColumnStoryblok,
  NavDropdownItemStoryblok,
} from '@vendure/codegen/storyblok'
import { Button } from '@vendure/components/ui'
import { getPrefixedLink } from '@vendure/storyblok-helpers'
import { motion } from 'framer-motion'
import { MdArrowRight } from 'react-icons/md'
import { CustomLink, GradientCard, StoryblokImage } from '../atoms'
import { RichText } from '../atoms/RichText'

interface DropdownMenuColumnProps {
  blok: NavDropdownItemLinkCardStoryblok | NavDropdownItemListColumnStoryblok
}

export const DropdownMenuColumn: React.FC<DropdownMenuColumnProps> = ({
  blok,
}) => {
  if (blok.component === 'navDropdownItemListColumn') {
    return (
      <div>
        <p className="text-sm font-medium text-white/70">{blok.title}</p>
        <div className="mt-4 flex flex-col">
          {blok.linkItems?.map((linkItem) => {
            const href = getPrefixedLink(linkItem.target)
            return (
              <CustomLink
                className="-mx-4 w-full rounded-md px-4 py-2 hover:bg-white/10"
                href={href}
                key={linkItem._uid}
                target={href.startsWith('http') ? '_blank' : '_self'}
              >
                <span className="block font-medium text-white">
                  {linkItem.navTitle}
                </span>
                <span className="text-sm text-white/60">
                  {linkItem.navSubTitle}
                </span>
              </CustomLink>
            )
          })}
        </div>
      </div>
    )
  }
  if (blok.component === 'navDropdownItemLinkCard') {
    return (
      <div>
        <div className="mb-2 flex items-center justify-between">
          <p className="font-semibold text-white">{blok.title}</p>
          <Button
            className="text-primary-400 hover:text-primary-300 font-semibold"
            variant="ghost"
            asChild
          >
            <CustomLink href={getPrefixedLink(blok.secondaryTarget)}>
              {blok.secondaryTargetText} <MdArrowRight />
            </CustomLink>
          </Button>
        </div>
        <CustomLink
          className="transition-all hover:opacity-80"
          href={getPrefixedLink(blok.mainTarget)}
        >
          {blok.image?.filename && (
            <GradientCard>
              <StoryblokImage
                width={400}
                height={225}
                image={blok.image}
                className="aspect-video w-full object-cover object-left"
                src={blok.image.filename}
                alt={blok.image.alt ?? blok.title ?? ''}
              />
            </GradientCard>
          )}
          <RichText
            containerProps={{ className: 'mt-2' }}
            document={blok.content}
          />
        </CustomLink>
      </div>
    )
  }
}

interface DropdownMenuProps {
  blok?: NavDropdownItemStoryblok
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ blok }) => {
  return (
    <div className="container">
      <motion.div>
        <div className="grid grid-cols-3">
          {blok?.columns?.map((column) => {
            return (
              <div
                className="h-full break-inside-avoid px-12 py-10 first:pl-0"
                key={column._uid}
              >
                <DropdownMenuColumn blok={column} />
              </div>
            )
          })}
        </div>
      </motion.div>
    </div>
  )
}

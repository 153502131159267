'use client'

import type { AssetStoryblok } from '@vendure/codegen/storyblok'
import { getPrimaryColorFilter } from '@vendure/helpers'
import type React from 'react'
import { Counter } from './Counter'
import { StoryblokImage } from './StoryblokImage'

interface IBaseStatistic {
  number?: number
  label?: string
  icon?: AssetStoryblok
}

export const BaseStatistic: React.FC<IBaseStatistic> = ({
  number = 0,
  label,
  icon,
}) => {
  return (
    <div className="m-auto w-fit rounded-md border-white/20 px-0 py-2 md:m-0">
      <div className="flex items-center">
        {icon?.filename && (
          <StoryblokImage
            src={icon.filename}
            alt={label ?? ''}
            className="mr-2"
            width={100}
            height={100}
            style={{
              filter: getPrimaryColorFilter(),
              width: 'auto',
              height: '38px',
            }}
          />
        )}
        <div className="text-3xl font-bold leading-none text-white">
          <Counter
            from={0}
            to={number}
            precision={0}
          />
        </div>
      </div>
      <span className="text-center text-sm font-medium">{label}</span>
    </div>
  )
}

'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type { ConfigStoryblok } from '@vendure/codegen/storyblok'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { NavigationBanner } from '../molecules'
import { Navbar } from './Navbar'

interface NavigationWrapperProps extends PropsWithChildren {
  config?: ConfigStoryblok
}

export const NavigationWrapper: React.FC<NavigationWrapperProps> = ({
  config,
  children,
}) => {
  const headerMenu = config?.headerMenu?.at(0)
  const [navHidden, setNavHidden] = useState(false)
  const { scrollY } = useScroll()

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const prev = scrollY.getPrevious() ?? 0
    setNavHidden(latest > prev && latest > 150)
  })

  if (!headerMenu) {
    return <></>
  }

  return (
    <header
      className="w-full"
      {...storyblokEditable(headerMenu)}
    >
      <motion.div
        className="z-overlay fixed left-0 right-0 top-0 w-full"
        animate={navHidden ? 'hidden' : 'visible'}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
        variants={{
          visible: {
            y: 0,
          },
          hidden: { y: '-100%' },
        }}
      >
        {config?.headerBannerActive && <NavigationBanner config={config} />}
        <Navbar blok={headerMenu}>{children}</Navbar>
      </motion.div>
    </header>
  )
}

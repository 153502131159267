'use client'

import { animate, useInView } from 'framer-motion'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'

interface CounterProps {
  from: number
  to: number
  precision?: number
  duration?: number
}

export const Counter: React.FC<CounterProps> = ({
  from,
  to,
  precision = 2,
  duration = 2,
}) => {
  const nodeRef = useRef<HTMLParagraphElement | null>(null)
  const [number, setNumber] = useState<string | number>(0)
  const inViewPort = useInView(nodeRef)

  useEffect(() => {
    const controls = animate(from, to, {
      duration,
      onUpdate(value) {
        if (value) {
          setNumber(value.toFixed(precision).toString())
        }
      },
    })

    return () => {
      controls.stop()
    }
  }, [inViewPort])

  return <span ref={nodeRef}>{number}</span>
}

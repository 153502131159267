'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type {
  CaseStudyStoryblok,
  CustomerStoryblok,
} from '@vendure/codegen/storyblok'
import { Badge } from '@vendure/components/ui'
import { HiGlobe, HiTag } from 'react-icons/hi'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { CustomLink, GradientCard, Heading, StoryblokImage } from '../atoms'

interface CaseStudyWithCustomer extends CaseStudyStoryblok {
  customer?: StoryblokStory<CustomerStoryblok>
}

interface CaseStudyCardProps {
  caseStudy: StoryblokStory<CaseStudyWithCustomer>
}

export function CaseStudyCard({
  caseStudy: caseStudyStory,
}: CaseStudyCardProps) {
  const caseStudy = caseStudyStory.content
  const customer = caseStudy.customer
  return (
    <GradientCard
      containerProps={{ className: 'flex flex-col' }}
      as={CustomLink}
      href={`/${caseStudyStory.full_slug}`}
      {...storyblokEditable(caseStudy)}
    >
      {caseStudy.image?.filename && (
        <div className="aspect-video overflow-hidden">
          <StoryblokImage
            className="h-full w-full object-cover object-center"
            width={1200}
            height={0}
            src={caseStudy.image.filename}
            alt={caseStudy.image.alt ?? caseStudy.name ?? ''}
          />
        </div>
      )}
      <div className="flex grow flex-col justify-start px-6  py-8">
        <div className="mb-3 flex items-center gap-2">
          {caseStudyStory.tag_list.map((item) => {
            return (
              <Badge
                variant="primary"
                key={item}
              >
                {item}
              </Badge>
            )
          })}
        </div>
        <Heading
          as={'p'}
          className="mb-2 text-2xl font-bold tracking-tight text-white"
        >
          {caseStudy.name}
        </Heading>
        <div className="line-clamp-5 grow">{caseStudy.shortDescription}</div>
        {customer && (
          <>
            <hr className="my-5 border-white/20" />
            <div className="flex items-center gap-2">
              {customer.content.logo?.filename && (
                <GradientCard
                  outerProps={{
                    className:
                      'relative aspect-square h-12 bg-transparent w-12 mr-2 rounded-full',
                  }}
                  containerProps={{
                    className: 'flex items-center justify-center ',
                  }}
                >
                  <StoryblokImage
                    width={300}
                    height={0}
                    style={{
                      objectFit: 'contain',
                      objectPosition: 'center',
                      padding: '8px',
                      filter: 'brightness(0) invert(1)',
                    }}
                    src={customer.content.logo.filename}
                    alt={customer.content.name ?? ''}
                  />
                </GradientCard>
              )}
              <div>
                <div className="text-lg font-semibold text-white">
                  {customer.content.name}
                </div>
                <div className="flex gap-2 text-sm font-medium">
                  <div className="flex items-center">
                    <HiTag className="text-primary-400 mr-1" />
                    {caseStudy.category}
                  </div>
                  <div className="flex items-center">
                    <HiGlobe className="text-primary-500 mr-1" />
                    {caseStudy.location}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </GradientCard>
  )
}

'use client'

import { cn } from '@vendure/helpers'
import { PropsWithChildren } from 'react'
import { useNavContext } from '../providers/NavProvider'

export function MainContentWrapper({ children }: PropsWithChildren) {
  const { dropdownOpen } = useNavContext()

  return (
    <div
      className={cn([
        'main-wrapper ease-out-cubic transition-[opacity,transform,filter] duration-300',
        dropdownOpen
          ? 'translate-y-[4.5rem] scale-[0.96] blur-[50px] saturate-[2] duration-1000'
          : '',
      ])}
    >
      {children}
    </div>
  )
}

'use client'

import { motion, useScroll, useSpring, useTransform } from 'framer-motion'
import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useRef } from 'react'

interface RevealOnScrollProps extends PropsWithChildren {
  delay?: number
  disable?: boolean
}

export const RevealOnScroll: React.FC<RevealOnScrollProps> = ({
  children,
  disable = false,
}) => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'center center'],
  })

  const scrollYProgessSrping = useSpring(scrollYProgress, {
    stiffness: 50,
    mass: 2,
    restDelta: 0.001,
  })

  const opacity = useTransform(scrollYProgessSrping, [0, 0.5], [0, 1])
  const translateY = useTransform(scrollYProgessSrping, [0, 0.5], [50, 0])

  if (disable) {
    return children
  }

  return (
    <div ref={target}>
      <motion.div style={{ opacity, translateY }}>{children}</motion.div>
    </div>
  )
}

'use client'

import hljs from 'highlight.js'
import MarkdownIt from 'markdown-it'

export interface MarkdownProps {
  readmeMarkdown: string
}

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value
      } catch (__) {}
    }

    return '' // use external default escaping
  },
})

export const Markdown: React.FC<MarkdownProps> = (props) => {
  return (
    <div
      className="rendered-markdown prose prose-slate prose-headings:text-white prose-headings:font-accent max-w-none rounded  py-8 leading-relaxed"
      dangerouslySetInnerHTML={{ __html: md.render(props.readmeMarkdown) }}
    />
  )
}

export function formatDate(
  value: string,
  options?: { locale?: string } & ConstructorParameters<
    typeof Intl.DateTimeFormat
  >[1],
) {
  try {
    // Note: if no `locale` is provided, the browser's default
    // locale will be used.
    return new Intl.DateTimeFormat(options?.locale, options).format(
      new Date(value),
    )
  } catch (e: any) {
    // A fallback in case the NumberFormat fails for any reason
    return new Date(value).toISOString()
  }
}

'use client'

import { storyblokEditable } from '@storyblok/react'
import { FooterStoryblok } from '@vendure/codegen/storyblok'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@vendure/components/ui'
import { BorderBeam } from '@vendure/components/ui/magicui/border-beam'
import { useScroll } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Heading, RichText, StoryblokImage } from '../atoms'

export interface GetStartedProps {
  blok: FooterStoryblok
}

export const GetStarted: React.FC<GetStartedProps> = ({ blok }) => {
  const intl = useIntl()
  const [copied, setCopied] = useState(false)
  const container = useRef(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start center', 'start start'],
  })

  return (
    <div
      className="py-40"
      ref={container}
      {...storyblokEditable(blok)}
    >
      <div
        className="container flex flex-col items-center gap-6 text-center"
        style={{
          perspective: 800,
        }}
      >
        {blok.getStartedIcon?.filename && (
          <div className="shadow-lead-xl relative h-20 w-20 rounded-lg bg-gradient-to-b from-slate-800 to-slate-900 p-3">
            <StoryblokImage
              width={300}
              height={300}
              src={blok.getStartedIcon.filename}
              alt="Vendure"
              className="h-full w-full object-contain object-center"
            />
            <BorderBeam size={40} />
          </div>
        )}
        <div>
          <Heading className="section-heading mb-4 block">
            {blok.getStartedTitle}
          </Heading>
          <RichText document={blok.getStartedContent} />
        </div>
        <TooltipProvider>
          <Tooltip
            open={tooltipOpen}
            delayDuration={0}
          >
            <TooltipTrigger
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => {
                setTooltipOpen(false)
                setCopied(false)
              }}
              onClick={() => {
                if (blok.getStartedCommand) {
                  navigator.clipboard.writeText(blok.getStartedCommand)
                  setCopied(true)
                  setTimeout(() => {
                    setCopied(false)
                  }, 5000)
                }
              }}
              className="mt-6 inline-block rounded-md border border-slate-700 bg-slate-900 px-3 py-1 font-mono transition-all hover:[box-shadow:0_1px_40px_rgba(23,193,255,0.5)]"
            >
              $ {blok.getStartedCommand}
            </TooltipTrigger>
            {copied && (
              <TooltipContent>
                <p>
                  {intl.formatMessage({
                    id: 'general.copied',
                  })}
                </p>
              </TooltipContent>
            )}
            {!copied && (
              <TooltipContent>
                <p>
                  {intl.formatMessage({
                    id: 'general.copy_to_clipboard',
                  })}
                </p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  )
}

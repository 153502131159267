const GH_API_TOKEN = process.env.GH_API_TOKEN!

export function fetchSponsors () {
  return fetch('https://api.github.com/graphql', {
    method: 'post',
    headers: {
      Authorization: `Bearer ${GH_API_TOKEN}`,
    },
    body: JSON.stringify({
      query: `
          {
            user(login: "michaelbromley") {
              sponsorshipsAsMaintainer(first: 100) {
                nodes {
                  sponsorEntity {
                    ... on User {
                      avatarUrl
                      name
                      login
                      url
                      ...SponsorData
                    }
                    ... on Organization {
                      avatarUrl
                      name
                      login
                      url
                      ...SponsorData
                    }
                  }
                }
              }
            }
            }

            fragment SponsorData on Sponsorable {
            sponsorshipsAsSponsor(first: 10) {
              nodes {
                sponsorable {
                  ...on User {
                    name
                  }
                }
                tier {
                  name
                  monthlyPriceInDollars
                }
              }
            }
          }
        `,
    }),
  })
    .then(res => res.json())
    .then(res => {
      const sponsors = res.data.user.sponsorshipsAsMaintainer.nodes
        .map((n: any) => {
          const sponsor = n.sponsorEntity
          const amount = sponsor.sponsorshipsAsSponsor.nodes.find((n: any) => n.tier != null)?.tier.monthlyPriceInDollars ?? 0
          return {
            avatarUrl: sponsor.avatarUrl,
            name: sponsor.name,
            login: sponsor.login,
            url: sponsor.url,
            amount,
          }
        })
        .sort((a: { amount: number }, b: { amount: number }) => {
          return b.amount - a.amount
        })

      return groupSponsorsIntoBuckets(sponsors)
    })
}

export function groupSponsorsIntoBuckets<T extends { amount: number }> (sponsors: T[]): T[][] {
  const buckets = [500, 100, 0]
  const bucketMap = new Map<number, T[]>()
  for (const bucket of buckets) {
    bucketMap.set(bucket, [])
  }

  // eslint-disable-next-line no-labels
  outer: for (const sponsor of sponsors) {
    for (const bucket of buckets) {
      if (sponsor.amount >= bucket) {
        bucketMap.get(bucket)?.push(sponsor)
        // eslint-disable-next-line no-labels
        continue outer
      }
    }
  }
  return Array.from(bucketMap.values()).filter(bucket => bucket.length)
}

export function fetchContributors () {
  return fetch('https://api.github.com/repos/vendure-ecommerce/vendure/contributors?per_page=200')
    .then(res => res.json())
    .then(res => {
      const contributors = res.map((i: any) => ({
        login: i.login,
        avatarUrl: i.avatar_url,
        contributions: i.contributions,
        link: i.html_url,
      }))
      return contributors
    })
}

'use client'

import type { ConfigStoryblok } from '@vendure/codegen/storyblok'
import { Button } from '@vendure/components/ui'
import { getPrefixedLink } from '@vendure/storyblok-helpers'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { HiX } from 'react-icons/hi'
import { Md5 } from 'ts-md5'
import { CustomLink } from '../atoms'

const LS_KEY = 'headerBanner'

interface NavigationBannerProps {
  config: ConfigStoryblok
}

export const NavigationBanner: React.FC<NavigationBannerProps> = ({
  config,
}) => {
  const [isClosed, setIsClosed] = useState(true)

  const bannerStateKey = useMemo(() => {
    return (
      LS_KEY +
      Md5.hashStr(config.headerBannerLinkTarget?.cached_url ?? 'default')
    )
  }, [config.headerBannerLinkTarget])

  function updateBannerLocalStorage(val: boolean) {
    localStorage.setItem(bannerStateKey, val ? 'true' : 'false')
  }

  function getBannerLocalStorage() {
    const val = localStorage.getItem(bannerStateKey)
    return val === 'true'
  }

  function close() {
    setIsClosed(true)
    updateBannerLocalStorage(true)
  }

  // Initially, we set the banner to be closed if it's not already closed
  useEffect(() => {
    const isClosed = getBannerLocalStorage()
    setIsClosed(isClosed)
  }, [])

  return (
    <AnimatePresence>
      {!isClosed && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto', transition: { delay: 0.5 } }}
          className="bg-primary-500 md:text-md relative overflow-hidden  text-xs md:gap-6 md:text-sm"
        >
          <div className="container">
            <div className="flex w-full items-center justify-center gap-2 py-2 pr-4 md:pr-0">
              <span className="block font-semibold text-white drop-shadow-md">
                {config.headerBannerMessage}
              </span>
              {config.headerBannerLinkTarget && (
                <Button
                  size="xs"
                  variant="secondary"
                  asChild
                  onClick={() => {
                    setIsClosed(true)
                  }}
                >
                  <CustomLink
                    href={getPrefixedLink(config.headerBannerLinkTarget)}
                  >
                    {config.headerBannerLinkText}
                    <FiArrowRight className="ml-1" />
                  </CustomLink>
                </Button>
              )}
              <HiX
                className="absolute right-2 ml-4 h-6 w-6 cursor-pointer text-white md:right-5"
                onClick={() => {
                  close()
                }}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

import type { TitleWithAccentStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import type { ComponentType, HTMLProps, PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { AccentFont } from './AccentFont'

interface TitleWithAccentProps extends PropsWithChildren {
  title: TitleWithAccentStoryblok[] | undefined
  tag?: keyof JSX.IntrinsicElements | ComponentType | ''
  containerProps?: HTMLProps<HTMLDivElement>
}

export const TitleWithAccent: React.FC<TitleWithAccentProps> = (props) => {
  const title = useMemo(() => {
    return props.title?.at(0)
  }, [props.title])

  const accentFirst = useMemo(() => {
    return (
      <>
        {title?.accent && <AccentFont>{title.accent} </AccentFont>}
        {title?.normal && <span>{title.normal}</span>}
      </>
    )
  }, [title])

  const accentSecond = useMemo(() => {
    return (
      <>
        {title?.normal && <span>{title.normal}</span>}
        {title?.accent && <AccentFont> {title.accent}</AccentFont>}
      </>
    )
  }, [title])

  const items = props.title?.map((item) => {
    const HeadingTag = (props.tag ?? 'span') as keyof JSX.IntrinsicElements

    return (
      <HeadingTag
        className={cn(
          'font-bold leading-tight tracking-tight text-white',
          props.containerProps?.className,
          item.twoLines ? 'flex flex-col' : '',
          'font-accent block',
        )}
        key={item._uid}
      >
        {item.accentPosition === 'first' && accentFirst}
        {item.accentPosition === 'second' && accentSecond}
      </HeadingTag>
    )
  })

  return <div>{items}</div>
}

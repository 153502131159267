import { CustomLink } from './CustomLink'

interface ILanguageSwitcher {
  color?: any
}

export const LanguageSwitcher: React.FC<ILanguageSwitcher> = (props) => {
  return (
    <div className="flex items-center gap-2 font-medium leading-relaxed text-white/60">
      <CustomLink href="/">
        <span>DE</span>
      </CustomLink>
      <span>|</span>
      <CustomLink href="/en">
        <span>EN</span>
      </CustomLink>
    </div>
  )
}

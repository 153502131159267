import type { PluginStoryblok } from '@vendure/codegen/storyblok'
import { Badge } from '@vendure/components/ui'
import type React from 'react'
import { HiExternalLink } from 'react-icons/hi'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { CustomLink, GradientCard } from '../atoms'
import { RichText } from '../atoms/RichText'

interface IPluginCard {
  plugin: StoryblokStory<PluginStoryblok>
}

export const PluginCard: React.FC<IPluginCard> = ({ plugin }) => {
  return (
    <GradientCard containerProps={{ className: 'h-full p-8' }}>
      {plugin.content.communityPlugin && (
        <div className="mb-4 flex gap-2">
          <Badge className="bg-yellow-400/30">Community</Badge>
          <Badge variant="default">Free</Badge>
        </div>
      )}
      {!plugin.content.communityPlugin && (
        <div className="mb-4 flex gap-2">
          <Badge variant="primary">Enterprise</Badge>
          <div className="flex flex-row">
            <span className="mr-2">
              from EUR {plugin.content.pricePerMonth} / month
            </span>
          </div>
        </div>
      )}
      <p className="mb-2 text-lg font-bold text-white">{plugin.name}</p>
      <RichText document={plugin.content.shortDescription} />
      {plugin.content.githubRepository && (
        <CustomLink
          href={`https://github.com/${plugin.content.githubRepository}`}
          className="mt-4 block"
        >
          <div className="flex items-center gap-2">
            <span>View on GitHub</span>
            <HiExternalLink />
          </div>
        </CustomLink>
      )}
    </GradientCard>
  )
}

import { resolveRelationsDeep } from '@alpin11/storyblok-deep-relations-resolver'
import { StoryblokClient } from '@storyblok/react'
import type { ISbStoriesParams } from '@storyblok/react/rsc'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { EventBus } from './clientSideEventBus'

export const PRE_RESOLVE_EVENT = 'preResolveStory'
export const POST_RESOLVE_EVENT = 'postResolveStory'

const bus = EventBus.getInstance()

export interface IStoryResolverEvent {
  slug: string
  sbParams: ISbStoriesParams
  data: any
}

export const loadStoryWithAllResolvedRelations = async <T>(
  slug: string,
  storyblokApi: StoryblokClient,
  sbParams: ISbStoriesParams,
  deepResolveRelations: string[] = [],
) => {
  let { data } = await storyblokApi.get(`cdn/stories/${slug}`, sbParams)

  await bus.dispatch<IStoryResolverEvent>(PRE_RESOLVE_EVENT, {
    slug,
    sbParams,
    data,
  })

  data = await resolveRelationsDeep(
    storyblokApi,
    data,
    deepResolveRelations,
    sbParams,
  )

  await bus.dispatch<IStoryResolverEvent>(POST_RESOLVE_EVENT, {
    slug,
    sbParams,
    data,
  })

  return data as { story: StoryblokStory<T> }
}

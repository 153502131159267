'use client'

import { createContext, PropsWithChildren, useContext, useState } from 'react'

type NavContext = {
  dropdownOpen: boolean
  setDropdownOpen?: (isOpen: boolean) => void
}

const NavContext = createContext<NavContext>({
  dropdownOpen: false,
})

export const useNavContext = () => {
  const context = useContext(NavContext)
  if (context === undefined) {
    throw new Error('useOnboardingContext must be used inside NavProvider')
  }
  return context
}

export function NavProvider({ children }: PropsWithChildren) {
  const [navOpen, setNavOpen] = useState(false)

  return (
    <NavContext.Provider
      value={{
        dropdownOpen: navOpen,
        setDropdownOpen: setNavOpen,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

import { JSX } from 'react'
import { Resend } from 'resend'

interface EmailSettings<TPayload> {
  react: {
    (props: TPayload): JSX.Element
    PreviewProps: TPayload
  }
  to: string | string[]
  bcc?: string | string[]
  subject: string
}

export async function emailAndWebhookHandler<TPayload>(
  payload: TPayload,
  webhook: string | undefined = undefined,
  emailSettings: EmailSettings<TPayload>,
) {
  const resend = new Resend(process.env.RESEND_API_KEY)
  const MAIL_SENDER = process.env.RESEND_SENDER_ADDRESS ?? ''

  try {
    const { data, error } = await resend.emails.send({
      from: MAIL_SENDER,
      to: emailSettings.to,
      subject: emailSettings.subject,
      bcc: emailSettings.bcc,
      react: emailSettings.react(payload) as JSX.Element,
    })

    if (error) {
      return Response.json({ error }, { status: 500 })
    }

    if (webhook) {
      await fetch(webhook, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
    }

    return Response.json(data)
  } catch (error) {
    console.log(error)
    return Response.json({ error }, { status: 500 })
  }
}

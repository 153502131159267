type Node = Record<string, any> | undefined | null

interface Document {
  document: Node
}

export const removeColorProperty = (node: Node): Node => {
  if (Array.isArray(node)) {
    return node.map(removeColorProperty)
  }

  if (typeof node === 'object' && node !== null) {
    const newNode = { ...node }

    if (newNode.marks) {
      newNode.marks = newNode.marks.map((mark: Node) => {
        if (mark?.type === 'textStyle' && mark.attrs?.color) {
          const newAttrs = { ...mark.attrs }
          delete newAttrs.color
          return { ...mark, attrs: newAttrs }
        }
        return mark
      })
    }

    Object.keys(newNode).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      newNode[key] = removeColorProperty(newNode[key])
    })

    return newNode
  }

  return node
}

'use client'

import { loadStoryblokBridge, SbBlokData } from '@storyblok/js'
import { StoryblokComponent } from '@storyblok/react/rsc'
import { resolveRelations } from '@vendure/helpers'
import { useState } from 'react'

export const StoryblokBridge = ({ blok }: { blok: SbBlokData }) => {
  const [blokState, setBlokState] = useState(blok)
  loadStoryblokBridge()
    .then(() => {
      const { StoryblokBridge, location } = window
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: resolveRelations,
      })
      storyblokInstance.on(['published', 'change'], () => {
        location.reload()
      })
      storyblokInstance.on(['input'], (e) => {
        setBlokState(e?.story?.content)
      })
    })
    .catch((err) => console.log(err))
  return <StoryblokComponent blok={blokState} />
}

import type { MultilinkStoryblok } from '@vendure/codegen/storyblok'

export function getPrefixedLink(target?: MultilinkStoryblok) {
  if (!target) {
    return ''
  }

  if (target.linktype === 'url') {
    return target.cached_url ?? ''
  }

  return `/${target.cached_url}`
}

import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@vendure/helpers'

const badgeVariants = cva(
  'inline-flex items-center rounded-md border border-slate-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2  ring-slate-300',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-slate-50/80 text-slate-50 shadow hover:bg-slate-900/80',
        secondary:
          'border-transparent bg-slate-800/80 text-white/80 hover:bg-slate-600/80',
        destructive:
          'border-transparent bg-red-500 text-slate-50 shadow hover:bg-red-500/80',
        outline: 'text-white border-white/30',
        primary: 'bg-primary-500 text-white shadow border-primary-600',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }

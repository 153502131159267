import React, { ComponentProps } from 'react'
import { CustomLink } from './CustomLink'

export const TextLink: React.FC<ComponentProps<typeof CustomLink>> = ({
  children,
  ...props
}) => {
  return (
    <CustomLink
      {...props}
      className="text-primary-500 hover:text-primary-400"
    >
      {children}
    </CustomLink>
  )
}

export function PreventHubspotFormTracking() {
  return (
    <>
      <label
        htmlFor="cc-num"
        className="hidden"
      >
        credit card HubspotCollectedFormsWorkaround
        https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102
      </label>
      <input
        name="cc-num"
        className="hidden"
        required={false}
        readOnly={true}
        value="HubspotCollectedFormsWorkaround"
        id="cc-num"
      />
    </>
  )
}

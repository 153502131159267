'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { DefaultError, useMutation } from '@tanstack/react-query'
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
  useToast,
} from '@vendure/components/ui'
import { cn } from '@vendure/helpers'
import { ComponentProps } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { z } from 'zod'
import { PreventHubspotFormTracking } from '../atoms'
import CountrySelect from '../molecules/CountrySelect'
import { PhoneInput } from '../molecules/PhoneInput'

const GuidedDemoInquiryFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  company: z.string(),
  phone: z.string(),
  jobTitle: z.string(),
  country: z.string(),
  message: z.string(),
  gdprConsent: z.boolean(),
})

export type GuidedDemoInquiryFormSchemaType = z.infer<
  typeof GuidedDemoInquiryFormSchema
>

interface GuidedDemoInquiryFormProps extends ComponentProps<'form'> {}

export function GuidedDemoInquiryForm({
  className,
  ...props
}: GuidedDemoInquiryFormProps) {
  const form = useForm<GuidedDemoInquiryFormSchemaType>({
    resolver: zodResolver(GuidedDemoInquiryFormSchema),
  })
  const intl = useIntl()
  const { mutateAsync, isPending } = useMutation<
    unknown,
    DefaultError,
    GuidedDemoInquiryFormSchemaType
  >({
    mutationKey: ['guidedDemoInquiry'],
    mutationFn: async (data) => {
      const res = await fetch('/api/guided-demo-inquiry-form', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return await res.json()
    },
  })
  const { toast } = useToast()

  async function onSubmit(data: GuidedDemoInquiryFormSchemaType) {
    try {
      await mutateAsync(data)

      form.reset()

      toast({
        title: 'Inquiry sent',
        description: 'We will get back to you soon',
        variant: 'default',
      })
    } catch (e) {
      toast({
        title: 'An error occurred',
        description: 'Please try again later',
        variant: 'destructive',
      })
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('space-y-4', className)}
        {...props}
      >
        <PreventHubspotFormTracking></PreventHubspotFormTracking>
        <div className="grid gap-10 md:grid-cols-2">
          <div className="space-y-2">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>First name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="John"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Last name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Doe"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Email address</FormLabel>
                    <FormControl>
                      <Input
                        type={'email'}
                        placeholder="john.doe@acme.com"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Phone</FormLabel>
                    <FormControl>
                      <PhoneInput
                        value={field.value}
                        onChange={(val) => field.onChange(val?.toString())}
                        onBlur={(val) => field.onBlur()}
                      ></PhoneInput>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={form.control}
              name="company"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Company</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Acme Inc."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
          </div>
          <div className="space-y-2">
            <FormField
              control={form.control}
              name="jobTitle"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Job title</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="CEO"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <CountrySelect
                      className="w-full"
                      placeholder="Country"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    ></CountrySelect>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Message to us</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder={
                          'We want to build XYZ and are interested in ABC...'
                        }
                        rows={8}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            ></FormField>
          </div>
        </div>
        <FormField
          control={form.control}
          name="gdprConsent"
          render={({ field }) => (
            <FormItem className="space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>
                {intl.formatMessage({
                  id: 'shared.forms.gdpr_consent',
                })}
              </FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <Button
            disabled={isPending}
            variant="lead"
            className="mt-6"
          >
            Schedule a demo
          </Button>
        </div>
      </form>
    </Form>
  )
}

import type { LinkProps } from 'next/link'
import Link from 'next/link'
import type { ComponentProps, HTMLProps, PropsWithChildren } from 'react'

export const CustomLink: React.FC<
  ComponentProps<typeof Link> &
    PropsWithChildren &
    Omit<HTMLProps<HTMLAnchorElement>, 'href' | 'as'>
> = (props) => {
  const noHref = typeof props.href !== 'string'
  const isExternal =
    typeof props.href === 'string'
      ? props.href.includes('http')
      : props.href?.protocol?.includes('http')

  return isExternal || noHref ? (
    <a {...(props as HTMLProps<HTMLAnchorElement>)}>{props.children}</a>
  ) : (
    <Link {...(props as LinkProps)}>{props.children}</Link>
  )
}

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@vendure/helpers'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'transition-all bg-primary-500 text-primary-100 shadow hover:bg-primary-400',
        destructive:
          'transition-all bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'transition-all border border-input bg-background shadow-sm text-white hover:bg-white/5 hover:text-accent-foreground',
        secondary:
          'transition-all bg-slate-800 border border-slate-600 text-white transition-all font-semibold shadow-sm hover:[box-shadow:0_1px_40px_rgba(255,255,255,0.2)]',
        discord:
          'bg-discord text-white hover:bg-discord/80 shadow-sm border-discord/80',
        lead: 'text-white bg-primary-500 border border-primary-600 rounded-full transition-all font-semibold [text-shadow:_0_1px_12px_var(--tw-color-primary-700)]  hover:[box-shadow:0_1px_40px_rgba(23,193,255,0.5)]',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-8 px-3 text-xs',
        lg: 'h-12 text-lg px-8',
        icon: 'h-9 w-9',
        xs: 'h-6 px-2 text-[0.8rem]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }

'use client'

import { apiPlugin, storyblokInit } from '@storyblok/react/rsc'
import { PropsWithChildren } from 'react'

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_SB_PUBLIC_TOKEN,
  use: [apiPlugin],
})

export function StoryblokProvider({ children }: PropsWithChildren) {
  return children
}

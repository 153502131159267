'use client'

import { ComponentProps } from 'react'
import { CopyBlock as ImportedCopyBlock, dracula } from 'react-code-blocks'

export function CopyBlock(props: ComponentProps<typeof ImportedCopyBlock>) {
  return (
    <ImportedCopyBlock
      {...props}
      theme={dracula}
    />
  )
}

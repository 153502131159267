'use client'

import type { HTMLProps } from 'react'

export const AccentFont: React.FC<HTMLProps<HTMLSpanElement>> = (props) => {
  return (
    <span
      {...props}
      style={{
        backgroundImage:
          'linear-gradient(90deg, var(--tw-color-primary-200) 0%,var(--tw-color-primary-500) 100%)',
        backgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {props.children}
    </span>
  )
}

import type { ISbStoriesParams } from '@storyblok/react/rsc'
import { getStoryblokApi } from '@storyblok/react/rsc'

export type LooseObject = Record<string, any>

export async function loadTranslations(locale?: string) {
  const sbParams: ISbStoriesParams = {
    dimension: locale,
    datasource: 'translations',
    page: 1,
    per_page: 1000,
  }

  const storyblokApi = getStoryblokApi()
  const { data } = await storyblokApi.get('cdn/datasource_entries', sbParams)

  const processed: LooseObject = {}

  data.datasource_entries.forEach((item: any) => {
    processed[item.name] = item.dimension_value ?? item.value
  })

  return processed
}

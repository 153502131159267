'use client'

import { PropsWithChildren, createContext, useContext } from 'react'
import { StoryblokStory } from 'storyblok-generate-ts'

type StoryContext = {
  story?: StoryblokStory<unknown>
}

const StoryContext = createContext<StoryContext>({
  story: undefined,
})

export const useStoryContext = () => {
  const context = useContext(StoryContext)
  if (undefined === context) {
    throw new Error('useStoryContext must be used inside StoryblokProvider')
  }
  return context
}

interface StoryProviderProps extends PropsWithChildren {
  story?: StoryblokStory<unknown>
}

export function StoryProvider({ children, story }: StoryProviderProps) {
  return (
    <StoryContext.Provider value={{ story }}>{children}</StoryContext.Provider>
  )
}
